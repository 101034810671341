.learning-path-course-list .carousel.carousel-slider {
  position: relative;
}

.learning-path-course-list .slider-wrapper.axis-horizontal {
  padding: 16px 34px;
}

.learning-path-course-list .carousel .slider {
  gap: 16px;
  width: 168px !important;
}
