.table-container {
  max-width: 100%;
  overflow-x: auto;
  position: relative;
}

.fixed-col {
  position: sticky;
  background: white;
  z-index: 99;
}

.fixed-col:nth-child(1) {
  left: 0;
  z-index: 3;
  min-width: 60px;
}

.fixed-col:nth-child(2) {
  left: 60px;
  z-index: 3;
  min-width: 150px;
}

.fixed-col:nth-child(3) {
  left: 210px;
  z-index: 3;
  min-width: 160px;
}

.fixed-col:nth-child(4) {
  left: 370px;
  z-index: 3;
  min-width: 100px;
  border-right: solid 5px #dbe4f1 !important;
}

.fixed-col:last-child {
  right: 0;
  border-left: solid 5px #dbe4f1 !important;
}
