/* header */
.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0px;
}

.fc th {
  text-align: right !important;
  border-style: none;
}
.fc-head {
  border-style: none !important;
  display: none;
}
.fc-view {
  border-style: none;
}

/* text */
.fc-day-number {
  margin-right: 6px;
  margin-top: 6px;
}
.fc-widget-header {
  margin-right: 6px !important;
}

/* .fc-toolbar .fc-header-toolbar{
    border-style: none;
  } */
/* .MuiBox-root .css-1kmimft{ */
/* .fc th, .fc td {
    border-style: none !important;
  } */

/* text to day */
.fc-day {
  background-color: #ffffff !important;
}
.fc-today .fc-day-number {
  color: #ffffff;
  border-radius: 50%;
  background-color: #00008f;
  width: 28px !important;
  height: 28px !important;
  text-align: center;
}
.fc-dayGrid-view .fc-body .fc-row {
  max-height: 124px !important;
}
.fc-body .fc-widget-content .fc-day-grid-container {
  height: auto !important;
}
.fc td.fc-sun {
  background-color: #f8fafc !important;
}
.fc td.fc-sat {
  background-color: #f8fafc !important;
}
.fc th.fc-sun {
  background-color: #f8fafc !important;
}
.fc th.fc-sat {
  background-color: #f8fafc !important;
}
.fc .fc-event-container {
  padding: 4px 8px;
}
.fc .fc-content {
  padding: 0 4px;
}
.fc .fc-row .fc-content-skeleton td {
  border-width: 1px;
  border-bottom: 0;
  border-color: #dbe4f1;
}
.fc .fc-event {
  border-width: 2px;
  border-radius: 4px;
}
.fc .fc-title {
  font-weight: 500;
}
.fc-bgevent {
  opacity: 1;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #dbe4f1;
}

.event-box-flex {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 3px 6px 3px 6px;
  cursor: pointer;
}
.event-box-img {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.event-box-reject {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 24px;
  background-color: #c91432 !important;
}
.event-box-request-edit {
  border: 1px solid #494df4;
  border-left: 3px solid #494df4;
  border-radius: 4px;
}
.event-box-request-create {
  border: 1px solid #1cc54e;
  border-left: 3px solid #1cc54e;
  border-radius: 4px;
}
.event-box-request-delete {
  border: 1px solid #c91432;
  border-left: 3px solid #c91432;
  border-radius: 4px;
}
.event-box-deleted {
  border: 1px dashed #c91432;
  background-color: #eeeeee !important;
  color: '#999999';
  border-radius: 4px;
}

.event-box-waiting-approve-borrow {
  border: 1px dashed #ed6c02;
  border-left: 3px solid #ed6c02;
  border-radius: 4px;
}
.event-box-left {
  font-size: 14px !important;
  font-weight: 500;
  width: 130px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-box-icon-left {
  width: 9px;
  height: 10px;
  align-self: center;
  padding-right: 6px;
}

.event-box-left-deleted {
  color: #999999;
}

.event-box-img-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  border-radius: 64px;
}

.event-box-img-2 {
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  border-radius: 64px;
}

.event-box-img-left {
  position: relative;
  left: 5px;
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  border-radius: 64px;
}

.event-box-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  border-radius: 64px;
  color: #00008f;
  background: #ccefff;
  font-size: 14px;
}

.event-box-type-holiday {
  /* Calendar/Holiday */
  background: #ffffff;
  border: 1px solid #70ad47;
  box-sizing: border-box;
  border-radius: 4px;
}

.event-box-type-academy-activity {
  /* Calendar/BG Academy Activity */
  background: #ccefff;
  border-radius: 4px;
}

.event-box-type-academy-activity-borrow {
  background: #eeeeee;
  border: 1px dashed #7f7f7f;
  box-sizing: border-box;
  border-radius: 4px;
  color: #999999;
}

.event-box-type-training {
  /* Calendar/BG Training */
  background: #ffc3b9;
  border-radius: 4px;
}

.event-box-type-training-other {
  /* Calendar/BG Training  Other*/
  background: #ffc3b9;
  border-radius: 4px;
}

.event-box-type-leave-leave-annual-leave {
  /* Calendar/Annual Leave */
  background: #ffffff;
  border: 1px solid #9bc2e6;
  box-sizing: border-box;
  border-radius: 4px;
}

.event-box-type-leave-leave-birthday-leave {
  /* Calendar/Birthday Leave */
  background: #ffffff;
  border: 1px solid #7030a0;
  box-sizing: border-box;
  border-radius: 4px;
}

.event-box-type-leave-leave-family-care-leave {
  /* Calendar/Family Care Leave */
  background: #ffffff;
  border: 1px solid #f691ff;
  box-sizing: border-box;
  border-radius: 4px;
}

.event-box-type-leave-leave-personal-leave {
  /* Calendar/Personal Leave */
  background: #ffffff;
  border: 1px solid #ffc000;
  box-sizing: border-box;
  border-radius: 4px;
}

.event-box-type-leave-leave-sick-leave {
  /* Calendar/Sick Leave */
  background: #ffffff;
  border: 1px solid #ff9a58;
  box-sizing: border-box;
  border-radius: 4px;
}

.event-box-type-leave-leave-other-leave {
  /* Calendar/Other Leave */
  background: #ffffff;
  border: 1px solid #9fbeaf;
  box-sizing: border-box;
  border-radius: 4px;
}

.event-box-type-leave-leave-day-off-leave {
  /* Calendar/Day Off */
  background: #ffffff;
  border: 1px solid #07c4b6;
  box-sizing: border-box;
  border-radius: 4px;
}
