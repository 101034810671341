.hide-pdf {
  display: none !important;
}
.pagebreak-after {
  display: none;
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
  .pagebreak-after {
    page-break-after: always;
    display: block;
  }
}
