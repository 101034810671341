.react-player__preview {
  border-radius: 4px !important;
}

.react-player__shadow {
  display: none !important;
}

.react-player__play-icon {
  display: none !important;
}
