/* header */

#table-document {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.td-border  {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}
.last-item   {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.th-border  {
  height: 56px;
}



#title-document {
  text-align: left;
  width: 250px;
  padding-top: 8px;
  padding-bottom: 8px;
}

#action {
  text-align: center;
  width: 104px;
}

#document-heder {
  text-align: center;
  width: 120px;
  background: rgba(240, 118, 98, 0.5);
}

#document {
  text-align: center;
  width: 120px;
  background: rgba(240, 118, 98, 0.08);
}

